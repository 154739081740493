import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Content,
  ContentModal,
  HeaderModal,
  PaginationStyled,
  SubTitle,
  Title,
} from "./style";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  ListItem,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  Visibility,
  FilterList,
  Close,
  ArrowDownward,
} from "@mui/icons-material";
import moment from "moment";
import { Error } from "../Error";
import { Header } from "../Header";
import { FilterForm } from "./FilterForm";
import { filters, IFilter } from "./models";
import { apiOperation, apiAutenticacao, headers } from "../../services/api";
import { AnaliseSimples, Listar, Documento } from "../../models/analise";
import { cnpjMask, cnpjOrCpfMask } from "../../utils/mask";
import { Loading } from "../Loading";
import axios from 'axios';
import { id } from "date-fns/locale";
import { getSessionUser } from "../../utils/users";
import { IUser } from "../Login/ILogin";
import { Empresas } from "../../utils/empresa"

interface ChipData {
  id: string;
  value: string;
}

interface UsuarioTermoAceite {
  idUsuario: string;
  status: boolean;
  dataHora: Date;
  ip: string;
  userRequest: string;
}
interface ResponseUsuarioTermoAceite {
  statusCode: number;
  erro: boolean;
  erros: [];
  valor: boolean;
}

let usuarioLogado: IUser | null = null;

export const SearchHistory = () => {
  const navigate = useNavigate();
  const [termoUsuario, setTermoUsuario] = useState<UsuarioTermoAceite>();
  const [responsetermoUsuario, setResponseTermoUsuario] = useState<ResponseUsuarioTermoAceite>();
  const [dataFilter, setDataFilter] = useState<IFilter>({ ...filters });
  const [historyList, setHistoryList] = useState<AnaliseSimples[]>([]);
  const [chipDataList, setChipDataList] = useState<ChipData[]>([]);
  const [showLoading, setShowLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openTerm, setOpenTerm] = useState(false);
  const [page, setPage] = useState(0);
  const [carregaItensTriunfante, setCarregaItensTriunfante] = useState(false);
  const [carregarItensJnsSeguradora, setCarregarItensJnsSeguradora] = useState(false);
  const [pagination, setPagination] = useState({
    paginaCorrente: 0,
    quantidadePaginas: 0,
    tamanhoPagina: 10,
    quantidadeRegistros: 0,
  });

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [errorApi, setErrorApi] = useState({
    message: "",
    title: "",
    state: false,
  });

  const hasAnalysisData = historyList.some(item => item.seguimento);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function searchFilter(item: any) {
    const listFilters: ChipData[] = [];
    Object.keys(item).forEach((key) => {
      if (item[key]) {
        listFilters.push({
          id: key,
          value: item[key],
        });
      }
    });
    setDataFilter({ ...item });
    setChipDataList(listFilters);
    setOpen(false);
    getHistory(mountParams(item));
  }

  function deleteFilter(item: any) {
    if (item.id.includes("data")) {
      const newChipDataList = chipDataList.filter(
        (item) => !item.id.includes("data")
      );
      setChipDataList([...newChipDataList]);
      setDataFilter({ ...dataFilter, dataFim: "", dataInicio: "" });
    } else {
      const index = chipDataList.findIndex((chip) => chip.id === item.id);
      chipDataList.splice(index, 1);
      setChipDataList([...chipDataList]);
      setDataFilter({ ...dataFilter, [item.id]: "" });
    }
  }

  const formatDateToISO = (date: any) => {
    const formated = date.split("/").reverse().join("-");
    return moment(formated).toISOString();
  };

  const mountParams = (filter: IFilter) => {
    let str = "";
    if (filter.cnpj)
      str += `&cnpjOuCpf=${filter.cnpj
        .replaceAll(".", "")
        .replaceAll("-", "")}`;
    if (filter.dataFim) str += `&dataFinal=${formatDateToISO(filter.dataFim)}`;
    if (filter.dataInicio)
      str += `&dataInicial=${formatDateToISO(filter.dataInicio)}`;
    if (filter.nomeCliente) str += `&cliente=${filter.nomeCliente}`;
    if (filter.solicitante) str += `&solicitante=${filter.solicitante}`;
    return str;
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRecordsPerPage(event.target.value);
    setPagination({
      ...pagination,
      paginaCorrente: 0,
      tamanhoPagina: event.target.value | 10,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      paginaCorrente: newPage,
    });
  };

  const VerificaAssinatura = async () => {
    const config = {
      headers: headers()
    }

    var urlString = `usuario/termo/obter`;

    const response = await apiAutenticacao.get<ResponseUsuarioTermoAceite>(urlString, config);

    setOpenTerm(!response.data.valor);
  }
  const SalvaInformacoesTermo = async () => {
    const responseIp = await fetch('https://api64.ipify.org?format=json');
    const { ip } = await responseIp.json();
    const userRequest = navigator.userAgent;
    const config = {
      headers: headers()
    }

    const dadosTermo = {
      status: true,
      ip: ip,
      userRequest: userRequest,
    };
    var urlString = `usuario/termo/inserir`;

    await apiAutenticacao.post(urlString, dadosTermo, config);
  }

  const handleAceitar = async () => {
    SalvaInformacoesTermo();
    setOpenTerm(false);
  };

  const getHistory = async (params = "") => {
    try {
      const config = {
        headers: headers(),
      };
      setShowLoading(true);
      let urlString = `analise/listar
				?pagina=${pagination.paginaCorrente}
				&registrosPorPagina=${pagination.tamanhoPagina}`;

      urlString += params;
      const { data } = await apiOperation.get<Listar>(urlString, config);

      if (!data.erro) {
        setHistoryList([...data.valor.dados]);
        setPagination({
          ...pagination,
          quantidadePaginas: data.valor.quantidadePaginas,
          quantidadeRegistros: data.valor.quantidadeRegistros,
        });
        setShowLoading(false);
      } else {
        setErrorApi({
          title: "Não foi possivel se conectar a API no momento.",
          state: true,
          message: data.erros?.join("<hr />"),
        });
        setShowLoading(false);
      }
    } catch (error: any) {
      setErrorApi({
        title: `API Não disponivel`,
        message: `Caso o problema continue informe o seguinte erro: ${error?.response?.status}-${error?.response?.statusText}`,
        state: true,
      });
      setShowLoading(false);
    }
  };

  const Download = async (params = "") => {
    try {
      const config = {
        headers: headers(),
      };

      setShowLoading(true);
      let urlString = `analise/relatorio
				?analiseId=${params}`;

      const { data } = await apiOperation.get<Documento>(urlString, config);

      window.open(data.valor, "_blank", "noopener,noreferrer");

      setShowLoading(false);
    } catch (error: any) {
      setErrorApi({
        title: `API Não disponivel`,
        message: `Falha ao baixar relatório!`,
        state: true,
      });
      setShowLoading(false);
    }
  };

  useEffect(() => {
    VerificaAssinatura();
    getHistory();

    usuarioLogado = getSessionUser();

    if (usuarioLogado?.empresa === Empresas.Triunfante) {
      setCarregaItensTriunfante(true)
    }
    else if (usuarioLogado?.empresa === Empresas.JnsSeguradora) {
      setCarregarItensJnsSeguradora(true)
    }

  }, [pagination.paginaCorrente, pagination.tamanhoPagina]);

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Title>Histórico de Pesquisas</Title>
            <Button
              className={"buttonPesquisa"}
              onClick={() => navigate("/novapesquisa")}
            >
              Nova Pesquisa
            </Button>
        </Content>
        <Card sx={{ minWidth: 275 }}>
          {showLoading ? (
            <Loading hidden={!showLoading} />
          ) : (
            <>
              <CardContent>
                <Box display={"flex"}>
                  <ListItem key={"data.key"}>
                    {chipDataList?.map((data) => (
                      <Chip
                        style={{ marginRight: "12px" }}
                        key={data.id}
                        label={data.value}
                        color="primary"
                        onDelete={() => deleteFilter(data)}
                      />
                    ))}
                  </ListItem>
                  <Button
                    style={{ width: "20%" }}
                    variant="outlined"
                    onClick={handleOpen}
                    startIcon={<FilterList />}
                  >
                    Filtrar Dados
                  </Button>
                </Box>
              </CardContent>
              <CardContent>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        {!carregarItensJnsSeguradora && (
                          <TableCell>Nome da Empresa</TableCell>
                        )}
                        <TableCell align="left">CNPJ</TableCell>
                        {hasAnalysisData && <TableCell align="left">Tipo</TableCell>}
                        <TableCell align="left">Solicitante</TableCell>
                        <TableCell align="left">Data da Consulta</TableCell>
                        <TableCell align="center">Status</TableCell>
                        {!carregarItensJnsSeguradora && (
                          <TableCell align="center">Visualizar</TableCell>
                        )}
                        <TableCell align="center">Relatório</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyList.map((item) => (
                        <TableRow
                          key={item.analiseId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          hover
                        >
                          {!carregarItensJnsSeguradora && (
                            <TableCell component="th" scope="row">
                              {item.empresa}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {cnpjMask(item.cnpjOuCpf)}
                          </TableCell>
                          {hasAnalysisData && <TableCell align="left">{item.seguimento}</TableCell>}
                          <TableCell align="left">
                            {item.usuarioInclusao}
                          </TableCell>
                          <TableCell align="left">
                            {moment(item.dataInclusao).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  item.status === "Concluída"
                                    ? "#2e7d32"
                                    : item.status === "Processando"
                                      ? "gray"
                                      : item.status === "Análise em esteira"
                                        ? "orange"
                                        : "#A30000",
                                borderRadius: "15px",
                                width: "fit-content",
                                padding: "inherit",
                                color: "white",
                                margin: "auto",
                              }}
                            >
                              {item.status}
                            </div>
                          </TableCell>
                          {!carregarItensJnsSeguradora && (
                            <TableCell align="center">
                              <IconButton
                                onClick={() =>
                                  navigate(`/resultados/${item.analiseId}`)
                                }
                                color="primary"
                                aria-label="Visualizar"
                              >
                                <Visibility />
                              </IconButton>
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {item.status === "Concluída" || carregarItensJnsSeguradora ? (
                              <IconButton
                                onClick={() => Download(item.analiseId)}
                                color="primary"
                                aria-label="Visualizar"
                              >
                                <ArrowDownward />
                              </IconButton>
                            ) : (
                              "-"
                            )}
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* <Pagination
										sx={PaginationStyled}
										count={numberOfPages}
										page={page}
										onChange={(event, value) => onChangePage(value)}
									/> */}
                  <TablePagination
                    component="div"
                    count={pagination.quantidadeRegistros}
                    page={pagination.paginaCorrente}
                    onPageChange={handleChangePage}
                    rowsPerPage={recordsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Registros Por Páginas: "}
                  />
                </TableContainer>
              </CardContent>
            </>
          )}
        </Card>
        <Modal
          open={openTerm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ContentModal>
            <HeaderModal>
              <SubTitle><b>Termos e condições de uso da plataforma ou blog</b></SubTitle>
            </HeaderModal>
            <Box style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <p>Olá! Seja bem vindo a plataforma <b>CREDIT ON Inteligência de Crédito.</b></p>
              <br></br>
              <p>É muito importante a leitura, ciência e aceitação desse termo. Nele estão contidas todas as  normas  para uso da plataforma e a prestação de serviços, os processos e as regras financeiras.</p>
              <br></br>
              <p>Este documento, e todo o conteúdo da plataforma é oferecido por <b>CREDIT ON INTELIGENCIA DE CRÉDITO LTDA</b>, neste termo representado apenas por “CREDIT ON”, que regulamenta todos os direitos e obrigações com todos que acessam a plataforma, denominado neste termo como “CLIENTE”, resguardado todos os direitos previstos na legislação, trazem as cláusulas abaixo como requisito para acesso e visita do mesmo, situado no endereço <u><b>WWW.CREDITON.TECH</b></u></p>
              <br></br>
              <p>A permanência no website implica-se automaticamente na leitura e aceitação tácita do presente termo de uso a seguir. Este termo foi atualizado pela última vez em 22 de abril de 2024.</p>
              <br></br>
              <p><u><b>1. DA FUNÇÃO DA PLATAFORMA</b></u></p>
              <br></br>
              <p>A  CREDIT ON  é uma plataforma  digital  com objetivo de oferecer  serviços automatizados de cadastro e análise de crédito para CREDIT ONs que avaliam a carteira de clientes ou novos clientes (Pessoa Jurídica e Física) para a realização de negócios, de forma segura, rápida e eficiente. </p>
              <br></br>
              <p>O processo acontece em um ambiente digital que coleta informações de forma automática em banco de dados públicos e privados, extraindo informações de documentos digitais, analisando-as de acordo com a Política de crédito embarcada na plataforma. </p>
              <br></br>
              <p>A CREDIT ON não se responsabiliza pelas informações que são coletadas em Bancos de dados privados e públicos, pelas informações e dados extraídos de documentos digitais ou pela concessão do crédito a partir do resultado da análise de crédito com a sugestão de limites, por possíveis danos ou prejuízos oriundos da concessão do crédito, o que é de total responsabilidade do cliente.</p>
              <br></br>
              <p><u><b>2. DO ACEITE DOS TERMOS</b></u></p>
              <br></br>
              <p>Este documento, chamado “Termo de Uso”, aplicáveis a todos os CLIENTES da plataforma, foi desenvolvido por Thâmisa Gonzalez de Oliveira, OAB PR 80782  modificado com permissão para esta plataforma. </p>
              <br></br>
              <p>Este termo especifica e exige que todo usuário ao acessar a plataforma CREDIT ON, leia e compreenda todas as cláusulas do mesmo, visto que ele estabelece entre a CREDIT ON e o CLIENTE direitos e obrigações entre ambas as partes, aceitos expressamente pelo CLIENTE a permanecer navegando na plataforma da CREDIT ON.</p>
              <br></br>
              <p>Ao continuar acessando a plataforma, o CLIENTE expressa que aceita e entende todas as cláusulas e a política de privacidade e confidencialidade da informação, que contém informações claras e completas sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e CLIENTES da plataforma.  Caso o CLIENTE discorde de alguma cláusula ou termo deste contrato, o mesmo deve imediatamente entrar em contato com comercial@crediton.tech e interromper sua navegação de todas as formas e meios.</p>
              <br></br>
              <p>Os CLIENTES se comprometem a notificar  a CREDIT ON imediatamente, a respeito de qualquer uso não autorizado de sua conta, bem como seu acesso não autorizado por terceiros.</p>
              <br></br>
              <p>Este termo pode e irá ser atualizado periodicamente pela CREDIT ON , que se resguarda no direito de alteração, sem qualquer tipo de aviso prévio e comunicação. É importante que o CLIENTE confira sempre se houve movimentação e qual foi a última atualização do mesmo no começo da página.</p>
              <br></br>
              <p>A CREDIT ON, em razão de violação à legislação em vigor ou aos termos e condições gerais de uso do CLIENTE, conforme a situação, poderá, sem prejuízo de outras medidas, recusar qualquer solicitação de cadastro, advertir, suspender, desabilitar a conta de um usuário, seus anúncios ou aplicar uma sanção que impacte negativamente sua reputação.</p>
              <br></br>
              <p><u><b>3. DO GLOSSÁRIO</b></u></p>
              <br></br>
              <p>Este termo pode conter algumas palavras específicas que podem não ser de conhecimento geral. Entre elas:</p>
              <br></br>
              <p><li>CLIENTE: Pessoa jurídica que contrata e utilizará a plataforma CREDIT ON para cadastro e análise de crédito de sua carteira comercial, de qualquer forma e meio, que acesse através de computador, notebook, tablet, celular ou quaisquer outros meios, o website ou plataforma da CREDIT ON.</li></p>
              <p><li>ADMINISTRADOR: Pessoa física responsável pelo CLIENTE, com poderes de inserir papéis de usuários vinculados ao CLIENTE. </li></p>
              <p><li>CREDIT ON: Plataforma Digital que analisa automaticamente o crédito de pessoas jurídicas e físicas de acordo com a Política de Crédito embarcada na plataforma. </li></p>
              <p><li><b>NAVEGAÇÃO:</b> O ato de visitar páginas e conteúdo do website ou plataforma da CREDIT ON.</li></p>
              <p><li><b>COOKIES:</b> Pequenos arquivos de textos gerados automaticamente pela plataforma e transmitido para o navegador do CLIENTE, que servem para melhorar a usabilidade do CLIENTE.</li></p>
              <p><li><b>LOGIN:</b> Dados de acesso do CLIENTE ao realizar o cadastro junto a CREDIT ON, dividido entre usuário e senha, que dá acesso a funções restritas da plataforma.</li></p>
              <p><li><b>OFFLINE:</b> Quando a plataforma ou plataforma se encontra indisponível, não podendo ser acessado externamente por nenhum usuário.</li></p>
              <br></br>
              <p>Em caso de dúvidas sobre qualquer palavra utilizada neste termo, o CLIENTE deverá entrar em contato com a CREDIT ON através dos canais de comunicação encontradas na plataforma.</p>
              <br></br>
              <p><u><b>4. DO ACESSO AA PLATAFORMA</b></u></p>
              <br></br>
              <p>A plataforma funciona normalmente 24 (vinte e quatro) horas por dia, porém podem ocorrer pequenas interrupções de forma temporária para ajustes, manutenção, mudança de servidores, falhas técnicas ou por ordem de força maior, que podem deixar a plataforma indisponível por tempo limitado.</p>
              <br></br>
              <p>A CREDIT ON não se responsabiliza por nenhuma perda de oportunidade ou prejuízos que esta indisponibilidade temporária possa gerar aos usuários.</p>
              <br></br>
              <p>Em caso de manutenção que exigirem um tempo maior, a CREDIT ON irá informar previamente aos clientes da necessidade e do tempo previsto em que a plataforma ou plataforma ficará offline.</p>
              <br></br>
              <p>O acesso a plataforma será somente ao ADMINISTRADOR e a usuários autorizado por este, num processo dentro da plataforma.</p>
              <br></br>
              <p>Todos os dados estão protegidos conforme a Lei Geral de Proteção de Dados, e ao realizar o cadastro junto a plataforma, o CLIENTE concorda integralmente com a coleta de dados conforme a Lei e com a Política de Privacidade da CREDIT ON.</p>
              <br></br>
              <p><u><b>5. DA LICENÇA DE USO E CÓPIA</b></u></p>
              <br></br>
              <p>Todos os direitos são preservados, conforme a legislação brasileira, principalmente na Lei de Direitos Autorais (regulamentada na Lei nº 9.610/18), assim como no Código Civil brasileiro (regulamentada na Lei nº 10.406/02), ou quaisquer outras legislações aplicáveis.</p>
              <br></br>
              <p>Todo o conteúdo da plataforma é protegido por direitos autorais, e seu uso, cópia, transmissão, venda, cessão ou revenda, deve seguir a lei brasileira, tendo a CREDIT ON todos os seus direitos reservados, e não permitindo a cópia ou utilização de nenhuma forma e meio, sem autorização expressa e por escrita da mesma.</p>
              <br></br>
              <p><u><b>6. DAS OBRIGAÇÕES</b></u></p>
              <br></br>
              <p>O CLIENTE ao utilizar o website da CREDIT ON, concorda integralmente em:</p>
              <p><li>De nenhuma forma ou meio realizar qualquer tipo de ação que tente invadir, hacker, destruir ou prejudicar a estrutura da plataforma, plataforma da CREDIT ON ou de seus parceiros comerciais. Incluindo-se, mas não se limitando, ao envio de vírus de computador, de ataques de DDOS, de acesso indevido por falhas da mesma ou quaisquer outras forma e meio.</li></p>
              <p><li>De não realizar divulgação indevida nos comentários da plataforma de conteúdo de SPAM, concorrentes, vírus, conteúdo que não possua direitos autorais ou quaisquer outros que não seja pertinente a discussão daquele texto, vídeo ou imagem.</li></p>
              <p><li>Da proibição em reproduzir qualquer conteúdo da plataforma ou plataforma sem autorização expressa, podendo responder civil e criminalmente pelo mesmo.</li></p>
              <p><li>Com a Política de Privacidade da plataforma, assim como tratamos os dados referentes ao cadastro e visita na plataforma, podendo a qualquer momento e forma, requerer a exclusão dos mesmos, através do formulário de contato.</li></p>
              <br></br>
              <p><u><b>7. DA MONETIZAÇÃO E PUBLICIDADE</b></u></p>
              <br></br>
              <p>A CREDIT ON pode alugar ou vender espaços publicitários na plataforma, diretamente aos anunciantes, ou através de empresas especializadas.</p>
              <br></br>
              <p>Essas publicidades não significam nenhuma forma de endosso ou responsabilidade pelos mesmos, ficando o CLIENTE responsável pelas compras, visitas, acessos ou quaisquer ações referentes as estas empresas.</p>
              <br></br>
              <p>Todas as propagandas na plataforma serão claramente destacadas como publicidade, como forma de disclaimer da CREDIT ON e de conhecimento do CLIENTE.</p>
              <br></br>
              <p><u><b>8. DOS TERMOS GERAIS</b></u></p>
              <br></br>
              <p>A plataforma irá apresentar hiperlinks durante toda a sua navegação, que podem levar diretamente para outra página da CREDIT ON ou para sites externos.</p>
              <br></br>
              <p>Apesar da CREDIT ON apenas criar links para sites externos de extrema confiança, caso o usuário acesse um site externo, a CREDIT ON não tem nenhuma responsabilidade pelo meio, sendo uma mera indicação de complementação de conteúdo, ficando o mesmo responsável pelo acesso, assim como sobre quaisquer ações que venham a realizar neste site.</p>
              <br></br>
              <p>Em caso que ocorra eventuais conflitos judiciais entre o CLIENTE e a CREDIT ON, o foro elegido para a devida ação será o da comarca da CREDIT ON, mesmo que haja outro mais privilegiado.</p>
              <br></br>
              <p>Este Termo de uso é valido a partir de 22 de abril de 2024</p>
              <br></br>
              <br></br>
            </Box>
            <Button onClick={handleAceitar}>Aceitar</Button>
          </ContentModal>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ContentModal>
            <HeaderModal>
              <SubTitle>Filtar Dados</SubTitle>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </HeaderModal>
            <Box>
              {
                <FilterForm
                  onSave={(event) => searchFilter(event)}
                  dataFilter={dataFilter}
                />
              }
            </Box>
          </ContentModal>
        </Modal>
        <Error
          hasOpen={errorApi.state}
          handleClose={() => setErrorApi({ ...errorApi, state: false })}
          errorMsg={errorApi.message}
          title={errorApi.title}
        />
      </Container>
    </>
  );
};
